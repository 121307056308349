<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div class="row">
              <CCol col="6">
                <strong>My Profile</strong>
              </CCol>
            </div>
          </CCardHeader>
          <CCardBody>
            <CAlert
                :color="messageColor"
                closeButton
                :show.sync="dismissCountDown"
                fade
            >{{ err_msg }}
            </CAlert
            >
            <CTabs>
              <CTab title="Profile" active>
                <CForm
                    @submit.prevent="onProfileSubmit"
                    style="margin-top: 10px"
                >
                  <CRow>
                    <CCol md="6">
                      <div
                          class="form-group"
                          :class="{
                          'form-group--error': $v.admin_profile.name.$error,
                        }"
                      >
                        <label class="form__label"
                        >{{ $lang.admin_profile.form.name }}
                          <required_span
                          />
                        </label>
                        <input
                            :maxlength="maxlength.name"
                            type="text"
                            :placeholder="$lang.admin_profile.form.name"
                            class="form-control"
                            v-model="admin_profile.name"
                        />
                        <small
                            class="error"
                            v-if="
                            $v.admin_profile.name.$error &&
                            !$v.admin_profile.name.required
                          "
                        >{{
                            $lang.admin_profile.validation.required.name
                          }}</small
                        >
                      </div>
                    </CCol>
                    <CCol md="6">
                      <div
                          class="form-group"
                          :class="{
                          'form-group--error': $v.admin_profile.email.$error,
                        }"
                      >
                        <label class="form__label"
                        >{{ $lang.admin_profile.form.email }}
                          <required_span
                          />
                        </label>
                        <input
                            :maxlength="maxlength.email"
                            type="text"
                            :placeholder="$lang.admin_profile.form.email"
                            class="form-control"
                            :disabled="disabled"
                            v-model="admin_profile.email"
                        />
                        <small
                            class="error"
                            v-if="
                            $v.admin_profile.email.$error &&
                            !$v.admin_profile.email.required
                          "
                        >{{
                            $lang.admin_profile.validation.required.email
                          }}</small
                        >
                        <small
                            class="error"
                            v-if="
                            $v.admin_profile.email.$error &&
                            !$v.admin_profile.email.email
                          "
                        >{{
                            $lang.admin_profile.validation.email.email
                          }}</small
                        >
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6" :hidden="disabledLocationField">
                      <div
                          class="form-group"
                          :class="{
                          'form-group--error':
                            $v.admin_profile.mobileNumber.$error,
                        }"
                      >
                        <label class="form__label"
                        >{{
                            $lang.admin_profile.form.mobileNumber
                          }}
                          <required_span
                          />
                        </label>
                        <!--                        <input type="text" :placeholder="$lang.admin_profile.form.mobileNumber" class="form-control" v-model="admin_profile.mobileNumber"/>-->
                        <vue-tel-input
                            v-model="admin_profile.mobileNumber"
                            v-on:country-changed="countryChanged"
                            v-bind="bindProps"
                        ></vue-tel-input>
                        <small
                            class="error"
                            v-if="
                            $v.admin_profile.mobileNumber.$error &&
                            !$v.admin_profile.mobileNumber.required
                          "
                        >{{
                            $lang.admin_profile.validation.required.mobileNumber
                          }}</small
                        >
                      </div>
                    </CCol>
                    <CCol md="6" :hidden="disabledLocationField">
                      <div
                          class="form-group"
                          :class="{
                          'form-group--error': $v.admin_profile.address1.$error,
                        }"
                      >
                        <label class="form__label"
                        >{{
                            $lang.admin_profile.form.address1
                          }}
                          <required_span
                          />
                        </label>
                        <textarea
                            :maxlength="maxlength.address1"
                            :placeholder="$lang.admin_profile.form.address1"
                            class="form-control"
                            v-model="admin_profile.address1"
                        />
                        <small
                            class="error"
                            v-if="
                            $v.admin_profile.address1.$error &&
                            !$v.admin_profile.address1.required
                          "
                        >{{
                            $lang.admin_profile.validation.required.address1
                          }}</small
                        >
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="4" :hidden="disabledLocationField">
                      <div
                          class="form-group"
                          :class="{ 'form-group--error': '' }"
                      >
                        <label class="form__label"
                        >{{ $lang.admin_profile.form.country }}
                          <required_span
                          />
                        </label>
                        <v-select
                            :options="countryOptions"
                            v-model="admin_profile.country"
                            @option:selected="loadState"
                        ></v-select>
                        <small
                            class="error"
                            v-if="
                            $v.admin_profile.country.$error &&
                            !$v.admin_profile.country.required
                          "
                        >{{
                            $lang.admin_profile.validation.required.country
                          }}</small
                        >
                      </div>
                    </CCol>
                    <CCol col="4" :hidden="disabledLocationField">
                      <div
                          class="form-group"
                          :class="{
                          'form-group--error': $v.admin_profile.state.$error,
                        }"
                      >
                        <label class="form__label"
                        >{{ $lang.admin_profile.form.state }}
                          <required_span
                          />
                        </label>
                        <v-select
                            :options="StateOptions"
                            v-model="admin_profile.state"
                            @option:selected="loadCity"
                        ></v-select>
                        <small
                            class="error"
                            v-if="
                            $v.admin_profile.state.$error &&
                            !$v.admin_profile.state.required
                          "
                        >{{
                            $lang.admin_profile.validation.required.state
                          }}</small
                        >
                      </div>
                    </CCol>
                    <CCol col="4" :hidden="disabledLocationField">
                      <div
                          class="form-group"
                          :class="{
                          'form-group--error': $v.admin_profile.city.$error,
                        }"
                      >
                        <label class="form__label"
                        >{{ $lang.admin_profile.form.city }}
                          <required_span
                          />
                        </label>
                        <v-select
                            :options="cityOptions"
                            v-model="admin_profile.city"
                        ></v-select>
                        <small
                            class="error"
                            v-if="
                            $v.admin_profile.city.$error &&
                            !$v.admin_profile.city.required
                          "
                        >{{
                            $lang.admin_profile.validation.required.city
                          }}</small
                        >
                      </div>
                    </CCol>
                  </CRow>

                  <div class="form-group" :class="{ 'form-group--error': '' }">
                    <ImageCropper @profileImage="getProfile($event)"/>
                  </div>

                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" type="submit"
                      >Update
                      </CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CTab>
              <CTab title="Change Password">
                <CAlert
                    color="danger"
                    closeButton
                    :show.sync="dismissCountDown"
                    fade
                >{{ err_password_msg }}
                </CAlert
                >
                <CForm @submit.prevent="onSubmit" style="margin-top: 10px">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                        ><CIcon name="cil-lock-locked"
                        /></span>
                      </div>
                      <input
                          :maxlength="maxlength.oldPassword"
                          type="password"
                          class="form-control"
                          v-model="change_password.oldPassword"
                          placeholder="Password"
                      />
                    </div>
                    <small
                        class="error"
                        v-if="
                        $v.change_password.$error &&
                        !$v.change_password.oldPassword.required
                      "
                    >{{ $lang.change_password.validation.oldPassword }}</small
                    >
                    <small
                        class="error"
                        v-if="
                        $v.change_password.oldPassword.$error &&
                        !$v.change_password.oldPassword.required
                      "
                    >{{
                        $lang.change_password.validation.required.oldPassword
                      }}</small
                    >
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                        ><CIcon name="cil-lock-locked"
                        /></span>
                      </div>
                      <input
                          :maxlength="maxlength.newPassword"
                          type="password"
                          class="form-control"
                          autocomplete="password"
                          v-model="change_password.newPassword"
                          placeholder="Password"
                      />
                    </div>
                    <small
                        class="error"
                        v-if="
                        $v.change_password.newPassword.$error &&
                        !$v.change_password.newPassword.required
                      "
                    >{{
                        $lang.change_password.validation.required.newPassword
                      }}</small
                    >
                    <small
                        class="error"
                        v-if="
                        $v.change_password.newPassword.$error &&
                        !$v.change_password.newPassword.minLength
                      "
                    >{{
                        $lang.change_password.validation.minLength.newPassword
                      }}</small
                    >
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                        ><CIcon name="cil-lock-locked"
                        /></span>
                      </div>
                      <input
                          :maxlength="maxlength.confirmPassword"
                          type="password"
                          class="form-control"
                          autocomplete="password"
                          v-model="change_password.confirmPassword"
                          placeholder="Confirm password"
                      />
                    </div>
                    <small
                        class="error"
                        v-if="
                        $v.change_password.confirmPassword.$error &&
                        !$v.change_password.confirmPassword.sameAsPassword
                      "
                    >{{
                        $lang.change_password.validation.sameAsPassword
                            .confirmPassword
                      }}</small
                    >
                  </div>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" type="submit"
                      >Submit
                      </CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CTab>
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  adminProfile,
  changePass,
  city,
  country,
  state
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
// import alerts from "../../components/layouts/general/alerts";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {
  minLength,
  maxLength,
  required,
  email,
  sameAs,
  requiredIf,
} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import {serverBus} from "../../main";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import {Maxlength} from "../../store/maxlength";

Vue.use(VueTelInput);
Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
import ImageCropper from "../ImageCropper";

export default {
  name: "AdminProfile",
  mixins: [Mixin],
  components: {
    required_span,
    // alerts,
    ImageCropper,
  },
  data() {
    return {
      bindProps: {
        mode: "international",
        placeholder: "Contact No.",
        defaultCountry: "IN",
        dropdownOptions: {
          showFlags: true,
        },
        inputOptions: {
          maxlength: Maxlength.admin_profile.mobile,
        },
        validCharactersOnly: true,
      },
      role: "",
      countryOptions: [],
      StateOptions: [],
      cityOptions: [],
      disabled: true,
      disabledLocationField: true,
      messageColor: "success",
      FILE: null,
      existImage: false,
      imageURL: null,
      avatarImage: null,
      imageOutput: "",
      saving: false,
      saved: false,
      adminId: "",
      err_msg: "",
      err_password_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      activePage: 1,
      module: adminProfile,
      moduleChangePassword: changePass,
      moduleCountry: country,
      moduleState: state,
      moduleCity: city,
      avatar: {
        imageURl: "",
      },
      change_password: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      admin_profile: {
        name: "",
        username: "",
        email: "",
        imageURL: "",
        imageRemove: "",
        address1: "",
        country: "",
        state: "",
        city: "",
        mobileNumber: "",
        mobileNumberCode: "",
      },
      maxlength: {
        name: Maxlength.admin_profile.name,
        email: Maxlength.admin_profile.email,
        address1: Maxlength.admin_profile.address1,
        oldPassword: Maxlength.admin_profile.oldPassword,
        newPassword: Maxlength.admin_profile.newPassword,
        confirmPassword: Maxlength.admin_profile.confirmPassword,
      },
    };
  },
  validations: {
    change_password: {
      oldPassword: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("newPassword"),
      },
    },
    admin_profile: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      address1: {
        requiredIf: requiredIf(function () {
          return this.disabledLocationField !== true;
        }),
      },
      country: {
        requiredIf: requiredIf(function () {
          return this.disabledLocationField !== true;

        }),
      },
      state: {
        requiredIf: requiredIf(function () {
          return this.disabledLocationField !== true;
        }),
      },
      city: {
        requiredIf: requiredIf(function () {
          return this.disabledLocationField !== true;

        }),
      },
      mobileNumber: {
        requiredIf: requiredIf(function () {
          return this.disabledLocationField !== true;
        }),
        // required,
        // numeric,
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
    },
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);

    localStorage.setItem("showAlert", "");
    self.adminId = localStorage.getItem("user_id");

    axios.get(this.listUrlApi(this.moduleCountry)).then((response) => {
      response.data.data.map(function (value) {
        self.countryOptions.push({value: value.id, label: value.name});
      });
    });

    axios
        .get(this.viewUrlApi(this.module, self.adminId))
        .then((response) => {
          const responseData = response.data.data;
          self.role = localStorage.getItem("userType");

          self.disabledLocationField = responseData.role === "Admin";

          if (responseData.user_type === "Admin") {
            self.disabled = false;
          }
          if (responseData.role !== "Admin") {
            self.admin_profile.mobileNumber = responseData.details.mobile;
            self.admin_profile.address1 = responseData.details.address1;
            self.admin_profile.country = {
              value: responseData.details.countryId,
              label: responseData.details.countryName,
            };
            self.admin_profile.state = {
              value: responseData.details.stateId,
              label: responseData.details.stateName,
            };
            self.admin_profile.city = {
              value: responseData.details.cityId,
              label: responseData.details.cityName,
            };
          }
          self.admin_profile.username = responseData.username;
          self.admin_profile.name = responseData.first_name;
          self.admin_profile.email = responseData.email;
          self.imageURL = responseData.original;
          self.avatarImage = responseData.avatar;
          localStorage.setItem("avatar", self.avatarImage);
          serverBus.$emit("PASS_THE_IMAGE", self.avatarImage);
          serverBus.$emit("send_cropper_image_url", self.avatarImage);
          self.StateOptions = [];
          axios
              .post(this.listUrlApi(this.moduleState), {
                countryId: self.admin_profile.country.value,
              })
              .then((response) => {
                response.data.data.map(function (value, key) {
                  self.StateOptions.push({value: value.id, label: value.name});
                });
              });
          self.cityOptions = [];
          axios
              .post(this.listUrlApi(this.moduleCity), {
                stateId: self.admin_profile.state.value,
              })
              .then((response) => {
                response.data.data.map(function (value, key) {
                  self.cityOptions.push({value: value.id, label: value.name});
                });
              });
          if (responseData.imageUrl !== "") self.existImage = true;
          store.commit("showLoader", false);
        })
        .catch(function (error) {
          store.commit("showLoader", false);
        });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },
  methods: {
    countryChanged(country) {
      let self = this;
      self.admin_profile.mobileNumberCode = "+" + country.dialCode;
      let mobile = self.admin_profile.mobileNumber;
      self.admin_profile.mobileNumber = mobile
          .replace("+" + country.dialCode, "")
          .trim();
    },
    loadState() {
      let self = this;
      const postData = {countryId: self.admin_profile.country.value};
      self.admin_profile.state = {}; //reset
      axios
          .post(this.listUrlApi(this.moduleState), postData)
          .then((response) => {
            self.StateOptions = [];
            response.data.data.map(function (value, key) {
              self.StateOptions.push({value: value.id, label: value.name});
            });
          });
    },
    loadCity() {
      let self = this;
      const postData = {stateId: self.admin_profile.state.value};
      self.admin_profile.city = {}; //reset
      axios
          .post(this.listUrlApi(this.moduleCity), postData)
          .then((response) => {
            self.cityOptions = [];
            response.data.data.map(function (value, key) {
              self.cityOptions.push({value: value.id, label: value.name});
            });
          });
    },

    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },
    onProfileSubmit() {
      this.$v.admin_profile.$touch();
      if (this.$v.admin_profile.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        let self = this;
        let mobile = self.admin_profile.mobileNumber;
        let mobileP = mobile
            .replace(self.admin_profile.mobileNumberCode, "")
            .trim();
        const formData = new FormData();

        formData.append("image", self.admin_profile.imageURL);
        formData.append("name", self.admin_profile.name);
        formData.append("email", self.admin_profile.email);
        formData.append("userId", self.adminId);

        if (self.role !== "Admin") {
          formData.append("address1", self.admin_profile.address1);
          formData.append("country", self.admin_profile.country.value);
          formData.append("state", self.admin_profile.state.value);
          formData.append("city", self.admin_profile.city.value);
          formData.append("mobileNumber", mobileP);
          formData.append(
              "mobileNumberCode",
              self.admin_profile.mobileNumberCode
          );
        }

        axios
            .post(this.updateUrlweb(this.module, self.adminId), formData)
            .then((response) => {
              if (response.data.code == 200) {
                self.err_msg = response.data.message;
                axios
                    .get(this.viewUrlApi(this.module, self.adminId))
                    .then((response) => {
                      (self.messageColor = "success"),
                          (self.imageURL = response.data.imageUrl);
                      self.avatarImage = response.data.avatar;
                      serverBus.$emit("PASS_THE_IMAGE", self.avatarImage);
                      if (response.data.imageUrl !== "") self.existImage = true;
                    });
                self.dismissCountDown = 10;
              } else {
                (self.messageColor = "danger"),
                    (self.err_msg = response.data.message);
                self.dismissCountDown = 10;
              }
            })
            .catch(function (error) {
              self.dismissCountDown = 10;
            });
      }
    },
    onSubmit() {
      this.$v.change_password.$touch();
      if (this.$v.change_password.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        let self = this;
        const postData = {
          oldPassword: self.change_password.oldPassword,
          newPassword: self.change_password.newPassword,
          confirmPassword: self.change_password.confirmPassword,
          email: localStorage.getItem("email"),
          token: localStorage.getItem("token"),
          username: localStorage.getItem("username"),
        };
        axios
            .post(this.createUrlApi(this.moduleChangePassword), postData)
            .then(function (response) {
              if (response.data.code == 200) {
                self.err_password_msg = response.data.message;
                self.dismissCountDown = 10;
              } else {
                self.err_password_msg = response.data.message;
                self.dismissCountDown = 10;
              }
            })
            .catch(function (error) {
              localStorage.setItem("token", "");
              self.dismissCountDown = 10;
              self.showLoginError();
            });
      }
    },
    getProfile(profile) {
      let self = this;
      self.admin_profile.imageURL = profile;
      if (profile === "") {
        self.admin_profile.imageRemove = 1;
      } else {
        self.admin_profile.imageRemove = 0;
      }
    },
    setAlert(value) {
      this.showAlert = value;
    },
    createTableRow() {
      this.$router.push({name: "Profile Create"});
    },
    rowClicked(item, index) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      axios
          .get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response) {
              self.data = response.data;
            } else {
              self.data = [];
            }
          });
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
